import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { COLORS } from "../colors";
import { withAuthentication } from "../../../containers/Session";
import { withFirebase } from "../../../containers/Firebase";
import { checkAndSuggestUsernames } from "../../../utilities/user_helpers";

import { FormGroup, Label, Input } from "reactstrap";
import UsernameSuggestions from "../UsernameSuggestions";
import { Button } from "../../../containers/UIKit";
import useOnboardingStore from "../../../stores/onboardingStore";
import ErrorMessage from "./ErrorMessage";
import TermsConditionsModal from "./TermsConditionsModal";

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
`;

const StyledLabel = styled(Label)`
  font-size: 18px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const StyledInput = styled(Input)`
  font-size: 16px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  border: 1px solid ${COLORS.primaryBlue};
  border-color: ${({ error }) =>
    error ? COLORS.errorRed : COLORS.primaryBlue};
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;

  &:focus {
    border-color: ${COLORS.primaryBlueHover};
    outline: none;
  }

  &:focus-visible {
    outline: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  height: 100%;
`;

const StyledButtonTitle = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  line-height: 22px;
  color: ${({ disabled }) =>
    disabled ? COLORS.primaryGreenDark : COLORS.primaryWhite};
`;

const OnboardingUserNameForm = ({ firebase, authUser }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const setCurrentStep = useOnboardingStore((state) => state.setCurrentStep);
  const [termsModalVisible, setTermsModalVisible] = useState(false);

  useEffect(() => {
    if (authUser) {
      setFirstName(authUser.firstName || "");
      setLastName(authUser.lastName || "");
      setUserName(authUser.userName || "");
      if (!authUser.agreeToTermsAndConditions) {
        setTermsModalVisible(true);
      }
    }
  }, [authUser]);

  useEffect(() => {
    setUserNameError("");
    setSuggestions([]);
    setFirstNameError("");
    setLastNameError("");
  }, []);

  const handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    } else if (name === "userName") {
      setUserName(value);
    }
  };

  const handleUsernameCheck = () => {
    if (userName && userName.trim().length > 0) {
      return new Promise((resolve, reject) => {
        checkAndSuggestUsernames(
          firebase,
          userName,
          (response) => {
            if (!response) {
              reject();
            } else {
              if (!response.available && response.suggestions) {
                setSuggestions(response.suggestions);
              }
            }
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    } else {
      return Promise.resolve();
    }
  };

  const validateFirstName = async () => {
    if (!firstName || firstName.trim().length === 0) {
      setFirstNameError("Please fill out your first name");
    } else {
      setFirstNameError("");
    }
  };

  const validateLastName = async () => {
    if (!lastName || lastName.trim().length === 0) {
      setLastNameError("Please fill out your last name");
    } else {
      setLastNameError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await validateFirstName();
    await validateLastName();
    if (!firstName || !lastName || !userName) return;
    const usernameCheckResponse = await handleUsernameCheck();
    if (!usernameCheckResponse || !usernameCheckResponse.available) {
      setUserNameError(
        "This username is already taken. Please choose another."
      );

      return;
    } else {
      setUserNameError("");
    }

    if (firstName && lastName && userName) {
      if (!authUser) return;
      setSubmitting(true);
      try {
        const userPath = `/user/${authUser.key}`;
        const userRef = firebase.database.ref(userPath);

        const userUpdates = {
          firstName: firstName.trim() || "",
          lastName: lastName.trim() || "",
          userName: userName.trim() || "",
        };

        await userRef.update(userUpdates);
        setFirstNameError("");
        setLastNameError("");
        setUserNameError("");
        setSuggestions([]);

        setSubmitting(false);
        setCurrentStep(2);
      } catch (error) {
        setSubmitting(false);
        console.error("Error updating user", error);
      }
    }
  };

  return (
    <FormContainer>
      {termsModalVisible && (
        <TermsConditionsModal onClose={() => setTermsModalVisible(false)} />
      )}
      <div className="row">
        <div
          className="col-sm-10"
          style={{
            padding: "0",
            margin: "0 auto",
            width: "100%",
          }}
          data-notranslate
        >
          <FormGroup className="mb-4">
            <StyledLabel>First Name</StyledLabel>
            <StyledInput
              name="firstName"
              value={firstName}
              onChange={handleUserInput}
              placeholder="First Name"
              onBlur={validateFirstName}
              onFocus={() => setFirstNameError("")}
              error={firstNameError}
            />
            {firstNameError && <ErrorMessage error={firstNameError} />}
          </FormGroup>
          <FormGroup className="mb-4">
            <StyledLabel>Last Name</StyledLabel>
            <StyledInput
              name="lastName"
              value={lastName}
              onChange={handleUserInput}
              placeholder="Last Name"
              onBlur={validateLastName}
              onFocus={() => setLastNameError("")}
              error={lastNameError}
            />
            {lastNameError && <ErrorMessage error={lastNameError} />}
          </FormGroup>
          <FormGroup className="mb-4">
            <StyledLabel>Username</StyledLabel>
            <StyledInput
              name="userName"
              value={userName}
              onChange={handleUserInput}
              placeholder="Username"
              onBlur={handleUsernameCheck}
              onFocus={() => {
                setUserNameError("");
                if (!userName && firstName && lastName)
                  setUserName(`${firstName}${lastName}`);
              }}
              error={userNameError}
            />
            {userNameError && <ErrorMessage error={userNameError} />}
            {suggestions?.length > 0 && (
              <UsernameSuggestions
                suggestions={suggestions}
                updateUsername={(suggestion) => setUserName(suggestion)}
                updateSuggestions={(suggestions) => setSuggestions(suggestions)}
                updateError={(error) => setUserNameError(error)}
              />
            )}
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <ButtonContainer>
            <Button
              className={`green-continue rounded-10 wide ${
                (submitting ||
                  !firstName ||
                  !lastName ||
                  !userName ||
                  (suggestions && suggestions.length > 0)) &&
                "disabled"
              }`}
              onClick={handleSubmit}
            >
              <StyledButtonTitle
                disabled={
                  submitting ||
                  !firstName ||
                  !lastName ||
                  !userName ||
                  (suggestions && suggestions.length > 0)
                }
              >
                Continue
              </StyledButtonTitle>
            </Button>
          </ButtonContainer>
        </div>
      </div>
    </FormContainer>
  );
};

export default withFirebase(withAuthentication(OnboardingUserNameForm));
