import React, { useState, useEffect } from "react";
import Select from "react-select";
import useLanguageStore from "../../../stores/useLanguageStore";

import {
  genderChoicesOptions,
  genderChoicesOptionsFrench,
} from "../../../containers/constants/GenderConstats";
import styled from "styled-components/macro";
import { COLORS } from "../colors";
import { Label } from "reactstrap";
import ErrorMessage from "./ErrorMessage";

const StyledLabel = styled(Label)`
  font-size: 18px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const OnboardingGenderForm = ({
  gender,
  onChange,
  error,
  onFieldChangeClearError,
}) => {
  const language = useLanguageStore((state) => state.language);
  const [genderOptions, setGenderOptions] = useState(genderChoicesOptions);

  useEffect(() => {
    if (language === "fr") {
      setGenderOptions(genderChoicesOptionsFrench);
    } else {
      setGenderOptions(genderChoicesOptions);
    }
  }, [language]);

  const handleUserInput = (selectedOption, action) => {
    const value = selectedOption ? selectedOption.value : "";
    onChange({ target: { name: action.name, value } });
    onFieldChangeClearError(action.name);
  };

  return (
    <div className="form-section" data-notranslate>
      <StyledLabel>Gender</StyledLabel>
      <Select
        name="gender"
        value={genderOptions.find((option) => option.value === gender)}
        onChange={handleUserInput}
        options={genderOptions}
        isSearchable
        styles={{
          control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            borderWidth: "1px",
            borderColor: error ? COLORS.errorRed : COLORS.primaryBlue,
            borderRadius: "10px",
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Manrope, sans-serif",
            color: `${COLORS.primaryBlack}`,
            width: "100%",
          }),
        }}
      />

      {error && <ErrorMessage error={error} />}
    </div>
  );
};

export default OnboardingGenderForm;
